import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, NavLink, Link, HashRouter } from "react-router-dom";
import './App.scss';
import { entries, tags, brands, brandTags, playlists, content } from "./app/data";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withRouter } from "react-router";
import HorizontalScroll from "react-scroll-horizontal";

console.log(entries);
console.log(tags);
console.log(brandTags);
console.log(playlists);
console.log(content);
const touchDevice = "ontouchstart" in document.documentElement;

// const tagIcon = "✥";
const tagIcon = "°";

// Number.prototype.mod = function (n: number) {
//   return ((this % n) + n) % n;
// };

function mod(v, n) {
  return ((v % n) + n) % n;
}

function App() {
  return (
    <HashRouter>
      <div id="App" className={`${touchDevice ? 'touchDevice' : ''}`}>
        <Header />

        <Route render={({ location }) => {

          const key = ["/", "/collagecvlt", "/shop"].includes(location.pathname) ? "gallery" : location.pathname;
          return (
            <TransitionGroup style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: 0,
            }}>
              <CSSTransition
                key={key}
                classNames="transition"
                timeout={750}
              >
                <Switch location={location}>
                  <Route exact={true} path={["/", "/collagecvlt", "/shop"]}
                    render={({ location }) => {
                      const params = new URLSearchParams(location.search);
                      const id = params.get("id");
                      const entry = entries.find(e => e.id === id);
                      return (<>
                        <Gallery entryActive={!!entry} />
                        <Entries activeEntry={entry} />
                        {/* <Entry entry={entry} active={!!entry} shop={shop} /> */}
                      </>)
                    }}
                  />
                  <Route path="/about" component={About} />
                  <Route path="/playlists" component={Playlists} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )
        }} />


      </div>
    </HashRouter>
  );
}

const Header = withRouter(({ location }) => {
  const brand = { "/": "studio", "/collagecvlt": "collagecvlt", "/shop": "shop" }[location.pathname]

  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");

  const [tagsOpen, set_tagsOpen] = useState(!!tag);
  useEffect(() => {
    if (tag)
      set_tagsOpen(true);
  }, [tag]);
  const toggleTags = () => {
    set_tagsOpen(!tagsOpen);
  }

  const tags = brandTags[brand];

  return (
    <header id="Header" className={`${tagsOpen ? 'tags-open' : ''}`}>
      <nav>
        <div className="space">
          <div className="header-item-container">
            <NavLink to="/" exact={true}>studio</NavLink>
            {brand === "studio" &&
              // <Link to={{ search: !tagsOpen ? "?tag=" : "" }}>
              <img tabIndex="0" alt="toggle tags" src="assets/icons/logo-arrowup.svg" className={`tag-toggle ${tagsOpen ? 'active' : ''}`} onKeyPress={toggleTags} onClick={toggleTags} />
              // </Link>
            }
          </div>
          <div className="header-item-container">
            <NavLink to="/collagecvlt">collagecvlt</NavLink>
            {brand === "collagecvlt" &&
              <img tabIndex="0" alt="toggle tags" src="assets/icons/logo-arrowup.svg" className={`tag-toggle ${tagsOpen ? 'active' : ''}`} onKeyPress={toggleTags} onClick={toggleTags} />
            }
          </div>
          <div className="header-item-container">
            {/* <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://www.etsy.com/shop/collagecvlt">shop</a> */}
            <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://collagecvlt.bigcartel.com/">shop</a>

            {/* <NavLink to="/shop">shop</NavLink>
            {brand === "shop" &&
              <img alt="toggle tags" src="assets/icons/logo-arrowup.svg" className={`tag-toggle ${tagsOpen ? 'active' : ''}`} onClick={toggleTags} />
            } */}
          </div>
        </div>
        <img alt="logo" className="logo" src="assets/icons/logo-eye.svg" />
        <div className="space">
          <div className="header-item-container">
            <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/jennyariane/">instagram</a>
          </div>
          <div className="header-item-container">
            <NavLink to="/about">about</NavLink>
          </div>
          <div className="header-item-container">
            <NavLink to="/playlists">playlists</NavLink>
          </div>
        </div>
      </nav>
      <div className={`tags-space ${brand && tagsOpen ? 'active' : ''}`}>
        {brands.map(b =>
          <Tags key={b} tags={brandTags[b]} active={tagsOpen && b === brand} height={60} inset={35} delay={0} />
        )}
      </div>
    </header >
  );
});

const Tags = withRouter(({ tags, active, location, height, light, inset, delay }) => {
  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");

  const activeRef = useRef();
  const firstElRef = useRef();
  const lastElRef = useRef();
  const containerRef = useRef();
  const tagsContainerRef = useRef();

  const [scrollTo, set_scrollTo] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      scrollToElement(activeRef.current);
    }, delay);
  }, [tag]);

  const scrollToElement = (el) => {
    if (el && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();

      let scrollDelta = null;
      if (el.getBoundingClientRect().left - containerRect.left - inset < 0)
        scrollDelta = (-el.getBoundingClientRect().left + containerRect.left + inset);
      if (el.getBoundingClientRect().right - containerRect.left + inset > containerRect.width)
        scrollDelta = (-el.getBoundingClientRect().right + containerRect.left + containerRect.width - inset);

      if (scrollDelta)
        if (touchDevice)
          tagsContainerRef.current?.scrollBy({ left: -scrollDelta, behavior: "smooth" })
        else
          set_scrollTo(scrollDelta);
    }
  }

  const tagsBody = () =>
    tags.map((t, i) =>
      <Link
        key={t + i}
        ref={t === tag ? activeRef : null}
        to={t === tag ? '?' : `?tag=${encodeURIComponent(t)}`}
        className={`tag ${t === tag ? "active" : ""}`}
        onFocus={(evt) => scrollToElement(evt.target)}
      >
        <span ref={{ 0: firstElRef, [tags.length - 1]: lastElRef }[i] ?? null}>{`${tagIcon} ${t} ${tagIcon}`}</span>
      </Link >
    )

  const [scrollLeft, set_scrollLeft] = useState(false);
  const [scrollRight, set_scrollRight] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (containerRef.current && firstElRef.current && lastElRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        set_scrollLeft(firstElRef.current.getBoundingClientRect().left - containerRect.left < 0);
        set_scrollRight(lastElRef.current.getBoundingClientRect().right - containerRect.left > containerRef.current.getBoundingClientRect().width);
      }
    }, 100);

    return () => clearInterval(interval)
  });

  const scrollAmount = 300;

  const onScrollLeft = () => {
    if (touchDevice)
      tagsContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    else
      set_scrollTo(scrollAmount + Math.random() * 0.0001)
  };
  const onScrollRight = () => {
    if (touchDevice)
      tagsContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    else
      set_scrollTo(-scrollAmount + Math.random() * 0.0001)
  };

  return (
    <div id="Tags" ref={containerRef} className={`${active ? "active" : ''} ${scrollLeft ? "scrollLeft" : ""} ${scrollRight ? "scrollRight" : ""}`} style={active ? { height } : null}>
      <img alt="scroll tags left" src={`/assets/icons/logo-arrowleft${light ? "-light" : ""}.svg`} className="left-arrow" onClick={onScrollLeft} />
      <img alt="scroll tags right" src={`/assets/icons/logo-arrowright${light ? "-light" : ""}.svg`} className="right-arrow" onClick={onScrollRight} />
      <div className="tags-body">
        {touchDevice
          ? <div ref={tagsContainerRef} className="tags-container">
            {tagsBody()}
          </div >
          : <HorizontalScroll
            animValues={scrollTo}
            reverseScroll={true}
          >
            {tagsBody()}
          </HorizontalScroll>
        }
      </div>
    </div >
  );
});

function Footer({ noMailingList }) {

  return (
    <footer id="Footer">
      <nav>
        <div className="space">
          <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/jennyariane/"><img alt="jennyariane instagram link" src="/assets/icons/logo-instagram.svg" />jennyariane</a>
          <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/collagecvlt/"><img alt="collagecvlt instagram link" src="/assets/icons/logo-instagram.svg" />collagecvlt</a>
          {/* <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://www.etsy.com/shop/collagecvlt"><img alt="etsy link" src="/assets/icons/logo-shop.svg" />etsy</a> */}
          <a className="external-url" target="_blank" rel="noreferrer noopener" href="https://collagecvlt.bigcartel.com"><img alt="big cartel link" src="/assets/icons/logo-shop.svg" />big cartel</a>
          {!noMailingList &&
            <Mailinglist />
          }
        </div>

      </nav>
    </footer>
  );
}

const columnMinWidth = 300;
const scrollbarSpace = touchDevice ? 0 : 18;

function calcColumns() {
  return Math.max(1, Math.floor((window.innerWidth) / columnMinWidth));
}

const gap = 5;

const Gallery = withRouter(({ location, entryActive }) => {
  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");

  const [columns, set_columns] = useState(calcColumns());
  const [size, set_size] = useState((window.innerWidth - scrollbarSpace) / columns);

  useEffect(() => {
    const callback = () => {
      const columns = calcColumns();
      set_columns(columns)
      set_size((window.innerWidth - scrollbarSpace) / columns);
    }
    window.addEventListener('resize', callback)
    window.addEventListener('orientationchange', callback)
  });

  const galleryRef = useRef();
  useEffect(() => {
    galleryRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname, tag]);

  const brand = { "/": "studio", "/collagecvlt": "collagecvlt", "/shop": "shop" }[location.pathname]
  const tagEntries = tag
    ? entries.filter(e => e.tags.includes(tag))
    : entries;

  const viewEntries = tagEntries.filter(e => e.brand === brand && !e.illustration);
  const illustrationEntries = tagEntries.filter(e => e.brand === brand && e.illustration);

  const _gap = (columns > 1 ? gap / (columns - 1) : gap);

  const calcIllustrationOffset = () => Math.ceil(viewEntries.length / columns) * (size + _gap)
    + (illustrationEntries.length ? 100 : 0);

  const [illustrationOffset, set_illustrationOffset] = useState(calcIllustrationOffset());

  const calcHeight = () => calcIllustrationOffset()
    + (illustrationEntries.length
      ? Math.ceil(illustrationEntries.length / columns) * (size + _gap)
      : 0);

  const [height, set_height] = useState(calcHeight());

  useEffect(() => {
    if (illustrationEntries.length)
      set_illustrationOffset(calcIllustrationOffset());
    set_height(calcHeight());
  }, [columns, brand, size, tag]);

  return (
    <section id="Gallery" style={{ "--columns": columns }} ref={galleryRef} className={`${entryActive ? 'entryActive' : ''}`}>
      <div className="images-container" style={{ height, minHeight: "100%" }}>
        {
          entries.map((e, i, arr) => {
            let position;
            let viewIndex;

            if (!e.illustration) {
              viewIndex = viewEntries.indexOf(e);
              position = {
                x: (viewIndex % columns) * (size + _gap),
                y: Math.floor(viewIndex / columns) * (size + _gap),
              }
            } else {
              viewIndex = illustrationEntries.indexOf(e);
              position = {
                x: (viewIndex % columns) * (size + _gap),
                y: Math.floor(viewIndex / columns) * (size + _gap) + illustrationOffset,
              }
            }

            return (
              <PreviewImage
                entry={e}
                key={e.id}
                active={viewIndex >= 0}
                position={position}
                size={size}
                index={viewIndex}
                columns={columns}
                noHorGap={columns === 1}
              />
            );
          })
        }
        <h1 className={`section-header ${illustrationEntries.length ? 'active' : ''}`} style={{ top: illustrationOffset }}>illustration & design</h1>
      </div>
      <Footer />
    </section >
  );
});

const PreviewImage = withRouter(({ entry, position, active, size, index, columns, noHorGap, location }) => {

  const imgSrc = entry.previewImage ?? entry.images[0]

  const [loaded, set_loaded] = useState(false);

  const [hasBeenActive, set_hasBeenActive] = useState(false);
  useEffect(() => active && !hasBeenActive && set_hasBeenActive(true), [active]);

  useEffect(() => {
    if (active) {
      const img = new Image();
      img.src = imgSrc;
      set_loaded(img.completed);
      img.onload = () => set_loaded(true);
      return () => img.onload = null;
    }
  }, [active]);

  const [_position, set_position] = useState(position);

  const [_active, set_active] = useState(false);
  const [_index, set_index] = useState(index);

  const delayIndex = Math.sqrt(Math.max(1, _index)) * 4;

  const delay = active
    ? index * 50 + 500 // appear
    : delayIndex * 50; // disappear

  const positionDelay = delayIndex * 50;

  useEffect(() => {
    const timeout = setTimeout(() => set_active(active), delay);
    return () => clearTimeout(timeout);
  }, [active]);

  useEffect(() => {
    if (active)
      set_position(position);
  }, [active, position]);

  useEffect(() => {
    if (active) {
      let timeout = setTimeout(() => set_index(index), delay);
      return () => clearTimeout(timeout);
    }
  }, [active, index]);


  const [hideSpinner, set_hideSpinner] = useState(false);
  useEffect(() => {
    if (!loaded)
      set_hideSpinner(false);
    else
      setTimeout(() => set_hideSpinner(true), 500);
  }, [loaded]);

  const style = {
    [false]: { left: _position.x, top: _position.y, width: size - (gap - gap / columns), height: size - (gap - gap / columns) },
    [true]: { left: _position.x, top: _position.y, width: size, height: size },
  }[noHorGap]

  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");

  const [hide, set_hide] = useState(false);
  useEffect(() => {
    if (!active) {
      const timeout = setTimeout(() => set_hide(true), 500 + delay + 100);
      return () => clearTimeout(timeout);
    } else {
      set_hide(false);
    }
  }, [active]);

  if (hide && !active && !_active)
    return null;

  return (
    <div id="Image" className={`${_active ? "active" : ""} ${loaded ? "loaded" : ""}`} style={{ ...style, transitionDelay: positionDelay + "ms" }} >
      {!hideSpinner && hasBeenActive &&
        <Loader index={index} />
      }
      <div className="image-body" style={{
        // transitionDelay: delay + "ms",
      }}>
        {(hasBeenActive || active) &&
          <img alt={entry.title} src={imgSrc} />
        }
        <div className={`overlay ${touchDevice ? 'touchDevice' : ''}`}>
          <Link className="overlay-link" to={{ search: `?id=${entry.id}${tag ? `&tag=${encodeURIComponent(tag)}` : ''}` }} />
          {!touchDevice &&
            <>
              <span className="title">{entry.title}</span>
              <div className="tags">
                <Tags tags={entry.tags.sort()} active={active} height={25} light={true} inset={0} delay={delay + 600} />
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
});

const Entries = withRouter(({ location, activeEntry }) => {
  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");
  const brand = { "/": "studio", "/collagecvlt": "collagecvlt", "/shop": "shop" }[location.pathname]

  const curEntries = entries.filter(e => e.brand === brand && (tag === null || e.tags.includes(tag)));
  const activeEntryIndex = curEntries.indexOf(activeEntry);
  const prevEntry = curEntries[mod(activeEntryIndex - 1, curEntries.length)];
  const nextEntry = curEntries[mod(activeEntryIndex + 1, curEntries.length)];

  return (
    <div id="Entries" className={`${activeEntry != null ? 'active' : ''}`}>
      {entries.map(entry => {
        const entryIndex = curEntries.indexOf(entry);

        let dir;
        if (mod(entryIndex - activeEntryIndex, curEntries.length) < curEntries.length / 2)
          dir = "right";
        else if (mod(entryIndex - activeEntryIndex, curEntries.length) > curEntries.length / 2)
          dir = "left";
        if (entryIndex === activeEntryIndex || !activeEntry)
          dir = null;

        return (
          <Entry key={entry.id} entry={entry} active={entry === activeEntry} prevEntry={prevEntry} nextEntry={nextEntry} dir={dir} />
        );
      })}
    </div >
  );
});

const Entry = withRouter(({ entry, active, location, prevEntry, nextEntry, dir }) => {
  const [hasBeenActive, set_hasBeenActive] = useState(false);
  useEffect(() => active && !hasBeenActive && set_hasBeenActive(true), [active]);

  const [animDone, set_animDone] = useState(false);
  const ref = useRef();
  useEffect(() => {
    let timeout;
    set_animDone(false);
    if (active) {
      ref.current.scrollTo({ top: 0 });

      timeout = setTimeout(() => {
        set_animDone(true);
        ref.current.focus();
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [active]);

  const to = {
    "studio": "/",
    "collagecvlt": "/collagecvlt",
    "shop": "/shop"
  }[entry?.brand || "studio"];

  const images = entry?.images;

  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");

  // const [hide, set_hide] = useState(false);
  // useEffect(() => {
  //   if (!active)
  //     setTimeout(() => set_hide(true), 750);
  //   else
  //     set_hide(false);
  // }, [active]);

  return (
    <div id="Entry" className={`${active ? "active" : ""} ${(dir) ?? ''} ${animDone ? 'animDone' : ''}`}>
      <div className="close">
        <Link to={{ pathname: to, search: tag && `?tag=${encodeURIComponent(tag)}` }}>
          {/* ← */}
          <img alt="close entry" className="prev" src="/assets/icons/logo-eye.svg" />
        </Link>
      </div>

      <div className="prev-next-container">
        <Link to={{ search: `?id=${prevEntry.id}${tag ? `&tag=${encodeURIComponent(tag)}` : ''}` }}>
          <img alt="previous entry" className="prev" src="/assets/icons/logo-arrowleft.svg" />
        </Link>
        <Link to={{ search: `?id=${nextEntry.id}${tag ? `&tag=${encodeURIComponent(tag)}` : ''}` }}>
          <img alt="next entry" className="next" src="/assets/icons/logo-arrowright.svg" />
        </Link>
      </div>

      <div className="entry-scroll" ref={ref} tabIndex="0">
        <div className="entry-body">
          <div className="entry-heading">
            <h1>{entry.title}</h1>
            <div className="quote-container">
              {entry.quote &&
                <p className="quote">
                  {entry.quote}
                </p>
              }
              {entry.author &&
                <p className="author">
                  {entry.author}
                </p>
              }
            </div>

            {entry.client &&
              <p className="client">{entry.client}</p>
            }
            {entry.links &&
              <ul className="links">
                {entry.links.map((l, i) =>
                  <li key={i}><a target="_blank" rel="noreferrer noopener" href={l.url}>{l.name}</a></li>
                )}
              </ul>
            }

          </div>
          {(hasBeenActive || active) && images.map((image, i) =>
            <img alt={`${entry.title} #${i + 1}`} src={image} key={image + i} />
          )}

          <div className="tags-container">
            {entry.tags.sort().map((tag, i) =>
              <Link key={tag + i} to={{ pathname: to, search: tag && `?tag=${encodeURIComponent(tag)}` }}>{`${tagIcon} ${tag} ${tagIcon}`}</Link>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div >
  )
});

const charms = [
  { alt: "instagram", src: "assets/icons/icon-instagram.svg", url: "https://www.instagram.com/jennyariane/" },
  { alt: "etsy", src: "assets/icons/icon-etsy.svg", url: "https://www.etsy.com/shop/collagecvlt" },
  { alt: "behance", src: "assets/icons/icon-behance.svg", url: "https://www.behance.net/jennylloyd" },
  { alt: "ello", src: "assets/icons/icon-ello.svg", url: "https://ello.co/jennyariane" },
  { alt: "pinterest", src: "assets/icons/icon-pinterest.svg", url: "https://pinterest.com/jennyariane" },
  { alt: "spotify", src: "assets/icons/icon-spotify.svg", url: "https://open.spotify.com/user/1162656781" },
  { alt: "society6", src: "assets/icons/icon-society6.svg", url: "https://society6.com/jennylloyd" },
  { alt: "twitter", src: "assets/icons/icon-twitter.svg", url: "https://twitter.com/jennyariane" },
  { alt: "facebook", src: "assets/icons/icon-facebook.svg", url: "https://www.facebook.com/jennylloyd.pictures" },
  { alt: "kofi", src: "assets/icons/icon-kofi.svg", url: "https://ko-fi.com/jennyariane" },
  { alt: "cv", src: "assets/icons/icon-cv.svg", url: "https://www.behance.net/jennylloyd/resume" },
  { alt: "inprnt", src: "assets/icons/icon-inprnt.svg", url: "https://www.inprnt.com/gallery/jennyariane", className: "wide" },
  // { alt: "artgrab", src: "assets/icons/icon-artgrab.svg", url: "https://artgrab.co/art/jennyariane", className: "wide" },
]

function About() {
  return (
    <div id="About">
      <div className="about-body">
        <div>
          <h1>jenny lloyd</h1>
          <p>
            {content.about}
          </p>
          {/* <div className="links">
            {content.links.map(l =>
              <a target="_blank" href={l.url}>{l.name}</a>
            )}
          </div> */}
          <div>
            <div className="charm-chain">
              {charms.map((charm, i) =>
                <a key={i} target="_blank" rel="noreferrer noopener" href={charm.url}><img {...charm} alt={charm.alt} /></a>
              )}
            </div>
            <div className="subtitle">
              All proceeds from my INPRNT store go to Unicorn Riot, Black Visions Collective and Black Queer & Trans Resistance Netherlands! After buying your card or print from the store, simply email me at <a target="_blank" rel="noreferrer noopener" href="mailto:hello@jennylloyd.pictures">hello@jennylloyd.pictures</a> to choose your fund; I will reply with a donation receipt. ♥
            </div>
          </div>

        </div>
        <Mailinglist />
      </div>
      <Footer noMailingList={true} />
    </div >
  );
}

function Mailinglist() {
  return (
    <div id="Mailinglist">
      <label>join the mailing list!</label>
      <form action="https://gmail.us6.list-manage.com/subscribe/post?u=47cbdaa49024ee08e03457866&amp;id=70224e0e0a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>

        <div className="input-container">

          {/* <div id="mc_embed_signup">
          <form action="https://gmail.us6.list-manage.com/subscribe/post?u=47cbdaa49024ee08e03457866&amp;id=70224e0e0a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <h2>Subscribe</h2> 
        <div class="mc-field-group">
          <label for="mce-EMAIL">Email Address </label>
          <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="email..." />
        </div>
        <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style={{ display: "none" }}></div>
          <div class="response" id="mce-success-response" style={{ display: "none" }}></div>
        </div>
        <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
          <input type="text" name="b_47cbdaa49024ee08e03457866_70224e0e0a" tabindex="-1" value="" />
        </div>
        <div class="clear">
          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
        </div>
      </div>
          </form>
        </div > */}
          <input type="email" name="EMAIL" placeholder="email..." />
          <button>sign up</button>
        </div >
      </form>
    </div >
  );
}

function Playlists() {
  return (
    <div id="Playlists">
      <div className="playlists-body">
        <div>
          <h1>playlists</h1>
          <p className="content">
            {content.playlists}
          </p>
        </div>

        {playlists.map((cat, i) =>
          <div key={i}>
            <h2>{cat.name}</h2>
            <div className="list">
              {cat.playlists.map(p =>
                <SpotifyWidget key={p.code} {...p} />
              )}
            </div>
          </div>
        )}

      </div>
      <Footer />
    </div>
  )
}

function SpotifyWidget({ code, src, title, subtitle }) {
  return (
    <div id="SpotifyWidget">
      <div className="square">
        <img alt={title} src={src} />
        <iframe title="spotify widget" src={`https://open.spotify.com/embed/playlist/${code}`} width="100%" height="100%" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <p >
        <span className="title">
          {title}
        </span>
        {
          subtitle && <> — <span className="subtitle">
            {subtitle}
          </span></>
        }
      </p >

    </div >
  );
}

const Loader = ({ index }) => {
  const delay = index * 150;
  return (
    <svg x="0px" y="0px"
      viewBox="0 0 400 400"  >
      {/* <g id="Layer_1" class="st0">
      </g> */}
      <g id="eye">
        <path d="M392.92978,147.54523C371.15485,122.72816,292.88065,41.76211,200.0078,41.76211S28.86071,122.72816,7.08578,147.54523
		c-6.55508,7.34827-6.55508,18.44229,0,25.79059c21.77493,24.81706,100.04911,105.78311,192.92203,105.78311
		s171.14705-80.96605,192.92198-105.78311C399.48486,165.98752,399.48486,154.89349,392.92978,147.54523L392.92978,147.54523z
		 M200.0078,54.94945c55.14662,0,105.28761,30.90956,140.01192,59.67427c-35.93167-19.93193-85.44014-39.89581-140.01192-39.89581
		S95.92754,94.69179,59.99586,114.62372C94.72842,85.85901,144.86115,54.94945,200.0078,54.94945L200.0078,54.94945z
		 M285.71783,160.4431c0.03192,25.35172-11.22693,49.40025-30.71588,65.61325c-17.99213,4.47511-36.45502,6.79301-54.99416,6.90942
		c-18.53917-0.11641-37.00204-2.43431-54.99417-6.90942c-19.45291-16.23358-30.70043-40.26566-30.70043-65.60191
		c0-25.33937,11.24752-49.36835,30.70043-65.60093c17.99213-4.48335,36.4509-6.81361,54.99417-6.93826
		c18.53914,0.11638,37.00203,2.43429,54.99416,6.90942C274.49091,111.03868,285.74976,135.09033,285.71783,160.4431
		L285.71783,160.4431z M16.17711,161.33624l-0.49962,0.65932c-0.25758-0.99307-0.25758-2.03975,0-3.03799l0.49962,0.62016
		c31.30719-24.84898,66.53528-44.31426,104.2327-57.60567c-25.73393,34.75626-25.73393,82.24554,0,117.0018
		C82.70825,205.67422,47.48429,186.19762,16.17711,161.33624L16.17711,161.33624z M200.0078,265.93158
		c-55.14665,0-105.28761-30.90956-140.01193-59.67427c35.93167,19.93193,85.44011,39.89581,140.01193,39.89581
		s104.08025-19.96388,140.01192-39.89581C305.28717,235.02202,255.15442,265.93158,200.0078,265.93158L200.0078,265.93158z
		 M384.3381,161.9554l-0.49966-0.61916c-31.30719,24.84901-66.53525,44.31738-104.2327,57.60568
		c25.73392-34.75729,25.73392-82.24554,0-117.00285c37.69745,13.28832,72.92551,32.75669,104.2327,57.60569l0.49966-0.65932
		C384.60388,159.8909,384.60388,160.94994,384.3381,161.9554L384.3381,161.9554z M384.3381,161.9554"/>
        <path className="eye" style={{ animationDelay: delay + "ms" }} d="M160.45088,140.65948c0,21.85117,17.70984,39.56204,39.55692,39.56204c21.84705,0,39.55687-17.71088,39.55687-39.56204
		c0-21.84704-17.70982-39.55689-39.55687-39.55689C178.16896,101.12318,160.47046,118.82069,160.45088,140.65948
		L160.45088,140.65948z M226.38248,140.65948c0,14.56779-11.81102,26.37469-26.37468,26.37469
		c-14.56369,0-26.37473-11.8069-26.37473-26.37469c0-14.56264,11.81104-26.36954,26.37473-26.36954
		C214.57146,114.28994,226.38248,126.09684,226.38248,140.65948L226.38248,140.65948z M226.38248,140.65948"/>
        <path d="M239.56467,358.23785c0-19.94839-30.89307-59.07059-34.41422-63.46121c-1.29599-1.48862-3.17091-2.34573-5.14265-2.34573
		c-1.97179,0-3.84671,0.85712-5.14268,2.34573c-3.52115,4.39063-34.41423,43.51282-34.41423,63.46121
		c0,21.84705,17.70984,39.55695,39.55692,39.55695C221.85484,397.7948,239.56467,380.0849,239.56467,358.23785L239.56467,358.23785z
		 M173.63307,358.23785c0-10.02878,14.55957-32.78549,26.37473-48.55963c11.81512,15.77414,26.37468,38.53085,26.37468,48.55963
		c0,14.56265-11.81102,26.36957-26.37468,26.36957C185.44411,384.60742,173.63307,372.80051,173.63307,358.23785
		L173.63307,358.23785z M173.63307,358.23785"/>
        <path d="M101.88302,266.5188c-1.58133-1.22284-3.63344-1.66171-5.5774-1.19504
		c-5.48055,1.31967-53.84754,13.37277-66.04388,29.15106c-13.12761,17.29156-9.86812,41.92734,7.30706,55.21152
		c17.17106,13.27905,41.83979,10.24103,55.27232-6.81259c12.20454-15.7793,11.70594-65.62253,11.605-71.25247
		C104.41006,269.61758,103.46847,267.74265,101.88302,266.5188L101.88302,266.5188z M82.41053,334.80847
		c-5.73089,7.55017-15.07052,11.44528-24.46679,10.20187c-9.39629-1.24756-17.40493-7.43689-20.97036-16.21713
		c-3.5696-8.78537-2.14896-18.8049,3.71894-26.25003c6.13676-7.91177,31.57299-17.0217,50.56747-22.28589
		C90.95795,299.96362,88.58337,326.86475,82.41053,334.80847L82.41053,334.80847z M82.41053,334.80847"/>
        <path d="M338.5105,358.23785c15.07465-0.02472,28.82547-8.6123,35.46088-22.1489
		c6.63644-13.5376,4.99844-29.66611-4.21753-41.59357c-12.19638-15.77927-60.56332-27.82312-66.04388-29.15103
		c-1.944-0.47079-4.00021-0.03293-5.58157,1.19501c-1.58542,1.22284-2.52701,3.09879-2.55893,5.10251
		c-0.10095,5.62994-0.59958,55.47318,11.60498,71.25143C314.67209,352.59146,326.24927,358.26157,338.5105,358.23785
		L338.5105,358.23785z M359.32327,302.56378c8.75342,11.52878,6.57974,27.9509-4.86865,36.80838
		c-11.44846,8.85336-27.89526,6.82495-36.84961-4.54309c-6.1409-7.9118-8.57108-34.83762-8.84921-54.55121
		c18.99445,5.23538,44.41928,14.31335,50.56747,22.26532V302.56378z M359.32327,302.56378"/>
        <path d="M193.41669,8.79631v13.18734c0,3.64169,2.94942,6.59623,6.59111,6.59623c3.64168,0,6.59108-2.95454,6.59108-6.59623
		V8.79631c0-3.63758-2.9494-6.59111-6.59108-6.59111C196.3661,2.2052,193.41669,5.15874,193.41669,8.79631L193.41669,8.79631z
		 M193.41669,8.79631"/>
        <path d="M117.94354,16.08482c-1.56586,0.77987-2.75674,2.15207-3.31203,3.81065c-0.55116,1.65757-0.4265,3.47274,0.35849,5.0376
		l6.59111,13.18322c1.05491,2.10878,3.15131,3.49746,5.50529,3.63858c2.34984,0.14012,4.59975-0.98174,5.89882-2.9463
		c1.30008-1.96765,1.45358-4.47406,0.39867-6.58287l-6.59109-13.18734c-0.785-1.56586-2.15309-2.75676-3.81477-3.31203
		C121.31944,15.17518,119.5084,15.29983,117.94354,16.08482L117.94354,16.08482z M117.94354,16.08482"/>
        <path d="M43.89517,56.36183c-2.01607,3.03078-1.19501,7.11856,1.83064,9.13874l12.16538,8.1168
		c3.02976,2.02018,7.12679,1.20325,9.14698-1.82652c2.0202-3.03078,1.20326-7.12679-1.82753-9.14698l-12.17262-8.10855
		c-1.45256-0.97042-3.23582-1.32379-4.95004-0.98177C46.37379,53.89557,44.86455,54.90516,43.89517,56.36183L43.89517,56.36183z
		 M43.89517,56.36183"/>
        <path d="M273.22278,19.03836l-6.59116,13.18734c-1.62561,3.25538-0.30591,7.2185,2.95358,8.84513
		c3.25949,1.62975,7.21951,0.30495,8.84921-2.95454l6.59116-13.18322c1.6297-3.25949,0.30594-7.22262-2.95358-8.84825
		C278.8125,14.45508,274.85251,15.77886,273.22278,19.03836L273.22278,19.03836z M273.22278,19.03836"/>
        <path d="M346.97754,54.53531l-12.17261,8.11679c-1.96045,1.30731-3.0751,3.56133-2.9216,5.91119
		c0.15247,2.34985,1.54941,4.44316,3.66129,5.48469c2.10876,1.04253,4.62036,0.88182,6.57974-0.4265l12.16541-8.12091
		c2.00369-1.28773,3.15857-3.55722,3.02155-5.93489c-0.14017-2.37868-1.55249-4.49981-3.69421-5.54134
		c-2.1449-1.04665-4.68015-0.85299-6.63956,0.50274V54.53531z M346.97754,54.53531"/>
      </g>
    </svg>

  );
}


export default App;
